import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Children, ReactElement, ReactNode } from "react";
import { Identity } from "./Identity";

export const NavLink = ({ children, href="#" }: { children: ReactNode, href?:string }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={href}
  >
    {children}
  </Link>
);

export const UserMenu = ({ children }) => {
  return children;
};

export const NavMenu = ({ children }) => {
  return children;
};

export const Brand = ({ children }) => {
  return children;
};

type MainNavBarChild = ReactElement<
  (typeof Brand | typeof NavMenu | typeof UserMenu | typeof Identity)
>;

type MainNavBarProps = { children?: MainNavBarChild | MainNavBarChild[] };

export function MainNavBar({ children = [] }: MainNavBarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const reactChildren = Children.toArray(children as any);

  const sortBucket = reactChildren.reduce((bucket, elem: any) => {
    const type = elem.type.displayName || elem.type.name;
    if (!bucket[type]) bucket[type] = [];
    bucket[type].push(elem);
    return bucket;
  }, {} as any);

  console.log(sortBucket);

  const navLinks = sortBucket[NavMenu.name];
  const userMenu = sortBucket[UserMenu.name];

  const brand = sortBucket[Brand.name];
  const identity = sortBucket[Identity.name];

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <HStack height="2em">
            {brand}
          </HStack>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {navLinks}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              {identity}
            </MenuButton>
            <MenuList>{userMenu}</MenuList>
          </Menu>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {navLinks}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
